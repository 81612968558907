.filterbar {
    position: fixed;
    top: 74.62px;
    background-color: rgb(48, 48, 48);
    height: 40px;
    width: 100%;
    z-index: 1029;
}

.filterbar-embed {
    top: 5px;
    background-color: initial;
    left: 40px;
}

.btn-group .btn-dark {
    background-color: rgb(34, 34, 34);
}
