.full-height {
  height: 100vh;
}

.full-height-max {
  max-height: 100vh;
}

.flex-grow {
  flex-grow: 1;
}
