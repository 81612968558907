.map-container {
    position: fixed;
    top: 114.62px;
    width: 100%;
    bottom: 0px;
}

.map-container-embed {
    top: 0px;
}

.mapboxgl-popup {
    max-width: 400px;
    font-family: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.mapboxgl-popup-content {
    background-color: rgb(34, 34, 34);
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: rgb(34, 34, 34) !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: rgb(34, 34, 34) !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: rgb(34, 34, 34) !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: rgb(34, 34, 34) !important;
}

.mapboxgl-ctrl-attrib-inner {
    text-align: right;
    color: rgb(0 0 0/75%);
}
